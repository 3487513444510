import React from 'react'
import ReactDOM from 'react-dom/client'

import App from './App'
import reportWebVitals from './reportWebVitals'

/* CSS */
import './design/css/app.css'
import './design/css/pincode.css'
import './design/css/game.css'
import './design/css/logo.css'
import './design/css/dealer.css'
import './design/css/timing.css'
import './design/css/pause.css'
import './design/css/messages.css'

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(<App />)

reportWebVitals()