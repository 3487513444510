const point = "https://rmpb.rekopgames.com"

/* DEALER AUTHORIZATION TOKEN */
const DEALER_MONITOR_TOKEN = '3b6d7df4-3371-4af1-8245-a8a3a5de831c'

/* SOCKET TOKEN */
const DEALER_MIDDLEWARE_TOKEN = 'c0d8c5ad-a850-4e2c-8949-c1ec7e4fb36d'

/* POINTS */
const startpoint = `${point}/api/`
const mediapoint = "https://cb.rekopgames.com"


export { point, mediapoint, startpoint, DEALER_MONITOR_TOKEN, DEALER_MIDDLEWARE_TOKEN }